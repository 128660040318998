
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface restoreData {
  document_type: string;
  document_id: string;
}

@Component
export default class ForgottenPasswordForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;

  private url_frontoffice = process.env.VUE_APP_FRONT_OFFICE_URL as string;

  options: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  protected formData: restoreData = {
    document_type: "1",
    document_id: ""
  };

  private credentials_found = true;
  private user_email = "";
  private confirm = false;

  private confirmEmail(): boolean {
    this.$axios
      .get("personas/recuperar/contrasena/email", {
        params: {
          filter: {
            where: {
              and: [
                { rut: this.formData.document_id },
                { tipo_documento: this.formData.document_type },
                { estado: 1 },
                { tipo: 1 }
              ]
            },
            fields: ["id", "rut", "tipo_documento", "email", "estado"]
          }
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.user.auth_token
        }
      })
      .then(response => {
        this.user_email = response.data;
        this.confirm = true;
      })
      .catch(error => {
        this.setCredentialsNotFound();
      });
    return true;
  }

  private confirmReset(): boolean {
    this.$q.loading.show();
    this.$axios
      .patch("personas/recuperar/contrasena/request", {
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id
      })
      .then(response => {
        this.$q.notify({
          message: this.$t("notification.email_reset_password") as string,
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.email_reset_error") as string,
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .finally(() => {
        this.$q.loading.hide();
        window.open(this.url_frontoffice, "_self");
      });
    return true;
  }

  private setCredentialsNotFound() {
    this.credentials_found = false;
    (this.$refs.form as any).validate();
  }

  private credentialsFound() {
    if (this.credentials_found) {
      return true;
    } else {
      return this.$t("validation.user_not_found");
    }
  }
  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }
}
